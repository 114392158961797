import React,{useRef} from 'react'
import './../../styles/Footer.scss'
import {Row,Col} from 'react-grid-system'
import Input from '../Input';
import Button from '../Button';
import Logo from './../../assets/images/footerLogo.svg'
import {Link} from 'react-router-dom'
import SecondFooter from './SecondFooter';

function Footer() {
    const myInput=useRef('');

    const  handleClick= () => {

        console.log(myInput.current.value)
        
      }
    return (
        <>
        <footer className="footer">
            <div className="container">
                <Row>
                    <Col  md={6} lg={4} xl={4} xxl={4} xs={12} sm={12} >
                        <h1>JOIN TO NEWSLETTER </h1>
                        <p className="text">In case you are interested in knowing more about Hussain, leave your email address in “newsletter” section so that we can inform you about the new interesting stuff about Hussain on a weekly basis. Your information is important and precious for us and it will not be given to a third party or advertising companies.</p>

                        <div className="footerNews">
                            <Input placeholder="E-mail" style={{ flex:3,paddingRight:'5px' }} myRef={myInput} clickHandle={handleClick}  />
                            <Button style={{flex:1}} text="JOIN" />

                        </div>
                       


                    </Col>
                    <Col md={6} lg={2} xl={2} xxl={2} xs={12} sm={12}>
                        <div className="wrapperFooter">
                            <h1>QUICK LINKS</h1>
                            <ul>
                                <li>
                                    <Link to='/'>
                                        HOME

                                    </Link>
                                </li>
                                <li>    
                                    <Link to='/aboutus'>
                                        ABOUT US

                                    </Link>
                                </li>
                                <li>    
                                    <Link to='/contactus'>
                                        CONTACT US

                                    </Link>
                                </li>
                            </ul>
                        </div>

                    </Col>
                    <Col md={6} lg={2} xl={2} xxl={2} xs={12} sm={12}>
                        <div className="wrapperFooter">

                            <h1>LIBRARY</h1>
                            <ul >
                                <li>
                                    <Link to='/'>
                                        GENRES

                                    </Link>
                                </li>
                                <li>    
                                    <Link to='/'>
                                    LANGUAGES

                                    </Link>
                                </li>
                                <li>    
                                    <Link to='/'>
                                        AUTHORS'S

                                    </Link>
                                </li>
                                <li>    
                                    <Link to='/'>
                                        ARTICLES'S

                                    </Link>
                                </li>
                            </ul>

                        </div>

                        

                    </Col>
                    <Col md={6} lg={4} xl={4} xxl={4} xs={12} sm={12}>
                        <h1>ABOUT US</h1>
                        <p className="text">

                        This website is one of the services of fifthgalaxy.org. We are always trying to collect the largest and most up-to-date collection of books, articles and dissertations about Hussain and the events of Ashura. You can help us spread Hosseini's knowledge by sending your content. Your content will be placed in the online library of Imam Hussain (AS) after reviewing and studying. It should be noted that duplicate and incorrect content will not be accepted.
                        </p>
                        <img src={Logo} alt="logo" />

                        

                

                    </Col>
                </Row>
           
            </div>
           
        </footer>
        <SecondFooter/>

        
        </>
    )
}
export default Footer;