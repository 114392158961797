const HOST = window.location.hostname;
let Configs = {};

const CL = (txt, color) => console.log(`%c ${txt}`, `color: ${color};font-size: 20px;`);



if (HOST === 'hussainlibrary.com') {
	CL('PRODUCTION', 'red');
	Configs = {
		API: 'https://api.hussainlibrary.com/api/v1/',
		IS_PRODUCTION: true
	};
} else {
	CL('DEVELOPMENT', 'blue');
	Configs = {
		API: 'https://api.hussainlibrary.com/api/v1/',
		IS_PRODUCTION: false
	};
}

export default Configs;
