/* eslint-disable */

import React from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { GlobalProvider } from "./context/Provider";

import Layout from "./components/Layout";
import Login from "./pages/Auth/Login";
import SignUp from "./pages/Auth/SignUp";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ReadOnline from "./pages/Source/ReadOnline";
import ResetPassword from "./pages/Service/ResetPassword";
import VerifyEmail from "./pages/Service/VerifyEmail";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { GoogleOAuthProvider } from "@react-oauth/google";

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
function App() {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
            <GlobalProvider>
                <GoogleOAuthProvider clientId={process.env.REACT_APP_ID}>
                    <Router>
                        <React.Suspense fallback={loading()}>
                            <Switch>
                                <Route path="/login" render={(props) => <Login {...props} />} />
                                <Route path="/signup" render={(props) => <SignUp {...props} />} />
                                <Route
                                    path="/forgotpassword"
                                    render={(props) => <ForgotPassword {...props} />}
                                />
                                <Route
                                    path="/service/verifyemail"
                                    render={(props) => <VerifyEmail {...props} />}
                                />
                                <Route
                                    path="/service/resetpassword"
                                    render={(props) => <ResetPassword {...props} />}
                                />
                                <Route
                                    path="/source/:BOOK_ID/read"
                                    render={(props) => <ReadOnline {...props} />}
                                />
                                <Route
                                    path="/"
                                    name="Home"
                                    render={(props) => <Layout {...props} />}
                                />
                            </Switch>
                        </React.Suspense>
                    </Router>
                </GoogleOAuthProvider>
            </GlobalProvider>
        </GoogleReCaptchaProvider>
    );
}

export default App;
