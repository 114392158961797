/* eslint-disable */ 

import React,{useEffect} from 'react'
import { isLogin, scrollToTop } from '../../helper'
import { useHistory } from "react-router-dom";

import './../../styles/Auth.scss'
import { Row,Col } from 'react-grid-system';
import SecondFooter from '../../components/Layout/SecondFooter';
import ForgotCard from '../../components/Auth/ForgotCard'

export default function ForgotPassword() {
    const history = useHistory();

    useEffect(() => {
        scrollToTop()
       if(isLogin()){

        history.push('/')

       }
    }, [])
    return (
        <div style={{overflowX:'hidden'}}>
        
                <Row>
                    <Col sm={3.5}></Col>

                    <Col sm={5}>
                        <div className="forgotpassword"  >

                            <ForgotCard type="forgot" />
                        
                        </div>
                    </Col>

                    <Col sm={3.5} ></Col>

                </Row>
                
            

            <SecondFooter/>

        </div>
    )
}
