import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Input from "../Input";
import Button from "../Button";
import { GmailIcon } from "../Icon";
import Label from "../Label";
import { setToken, getBackRoute, validateEmail } from "../../helper";
import { useHistory } from "react-router-dom";
import { Query } from "../../queries";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SocialButton from "./SocialButton";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { GoogleLogin } from "@react-oauth/google";

export default function FormCard({ type }) {
    let history = useHistory();

    const { executeRecaptcha } = useGoogleReCaptcha();

    const emailRef = useRef("");

    const passwordRef = useRef("");

    // useEffect(() => {

    //     GoHomeIsNotLogin()

    // }, [])

    // google login
    const handleGoogleLogin = async (user) => {
        if (!executeRecaptcha) {
            console.log("Execute recaptcha not yet available");
        } else {
            const result = await executeRecaptcha("submit");
            console.log(user);

            Query(
                {
                    url: "login/gmail/user",
                    method: "POST",
                    data: {
                        access_token: user._token.accessToken,
                        "g-recaptcha-response": result,
                    },
                },
                (response) => {
                    setToken(response.data.token, response.data._id);
                    if (getBackRoute()) {
                        history.push(getBackRoute());
                    } else {
                        history.push("profile/edit");
                    }
                },
                (error) => {
                    console.log(error);
                    error.response.data.message[0].message &&
                        toast.error(error.response.data.message[0].message);
                }
            );
        }
    };

    const handleGoogleLoginFailure = (err) => {
        console.error(err, "test for error");
    };

    const handleLogin = async () => {
        const emailVal = emailRef.current.value;
        const passwordVal = passwordRef.current.value;

        if (type === "login") {
            if (emailVal.length === 0 || passwordVal.length === 0) {
                toast.error("Please fill email and password");
            } else {
                if (!executeRecaptcha) {
                    console.log("Execute recaptcha not yet available");
                } else {
                    const result = await executeRecaptcha("submit");

                    Query(
                        {
                            url: "login/user",
                            method: "POST",
                            data: {
                                emailOrusername: emailVal,
                                password: passwordVal,
                                "g-recaptcha-response": result,
                            },
                        },
                        (response) => {
                            setToken(response.data.token, response.data._id);
                            if (getBackRoute()) {
                                history.push(getBackRoute());
                            } else {
                                history.push("profile/edit");
                            }
                        },
                        (error) => {
                            toast.error(error.response.data.message[0].message);
                        }
                    );
                }
            }
        } else {
            if (!validateEmail(emailVal)) {
                toast.error("Invalid emial address.");
            }
            if (passwordVal.length < 4) {
                toast.error("Password must be more than 4 character.");
            }

            if (validateEmail(emailVal) && passwordVal.length >= 4) {
                if (!executeRecaptcha) {
                    console.log("Execute recaptcha not yet available");
                } else {
                    const result = await executeRecaptcha("submit");

                    Query(
                        {
                            url: "register/user",
                            method: "POST",
                            data: {
                                email: emailVal,
                                password: passwordVal,
                                "g-recaptcha-response": result,
                            },
                        },
                        (response) => {
                            if (getBackRoute()) {
                                history.push(getBackRoute());
                            }

                            if (response.messages[0].message.includes("duplicated")) {
                                toast.error(response.messages[0].message);
                            } else {
                                toast.success(response.messages[0].message);
                            }
                        },
                        (error) => {
                            console.log(error);

                            error && toast.error(error.response.data.messages[0].message);
                        }
                    );
                }
            }
        }
    };

    return (
        <>
            <div
                style={{
                    flex: "1.5",
                    display: "fle",
                    flexDirection: "column",
                    boxShadow: "-10px 30px 40px 0 rgb(0 0 0 / 6%)",
                    borderRadius: "10px",
                }}
            >
                <div className="formAuthCard">
                    <div className="formTabs">
                        <Link to="/login">
                            <p className={"tabTitle " + (type === "login" && "active")}>LOGIN</p>
                        </Link>
                        <Link to="/signup">
                            <p className={"tabTitle " + (type === "signup" && "active")}>SIGNUP</p>
                        </Link>
                    </div>

                    <div style={{ marginTop: "50px" }}>
                        <Label text="Email" />
                        <Input myRef={emailRef} type="text" placeholder="Email or username" />

                        <Label text="Password" />

                        <Input
                            myRef={passwordRef}
                            type="password"
                            placeholder="Enter your password"
                        />

                        <Button
                            click={handleLogin}
                            text={type}
                            _style={{ width: "50%", marginTop: "50px", textTransform: "uppercase" }}
                        />

                        {type === "login" && (
                            <Link to="/forgotpassword">
                                <p className="forgotText">Forget password?</p>
                            </Link>
                        )}

                        <Link to="/signup">
                            <p className="forgotText">You do not have an account?</p>
                        </Link>
                    </div>
                </div>
                <div className="logInWith">
                    <p>Login with</p>
                    {/* <SocialButton
                        provider="google"
                        appId={process.env.REACT_APP_ID}
                        onLoginSuccess={handleGoogleLogin}
                        onLoginFailure={handleGoogleLoginFailure}
                    >
                        <GmailIcon />
                    </SocialButton> */}

                    <GoogleLogin
                        className="logInWith"
                        onSuccess={(credentialResponse) => {
                            handleGoogleLogin(credentialResponse);
                        }}
                        onError={() => {
                            handleGoogleLoginFailure();
                        }}
                    />
                </div>
            </div>

            <ToastContainer
                position="top-right"
                hideProgressBar={false}
                // autoClose={true}
                newestOnTop={true}
                closeOnClick={false}
                draggable={false}
                rtl={false}
                theme={"colored"}
            />
        </>
    );
}
