/* eslint-disable */ 

export default (state, action) => {
    switch(action.type) {
      case 'GET_POSTS':
        return {
          ...state,
          posts: {
            loading:false,
            error:false,
            data:action.payload
          }
        }
      case 'BLOG_ERROR': 
        return {
          ...state,
          posts: {
            loading:false,
            data:[],
            error:action.payload
          }
        }
      
      default:
        return state;
    }
  };
  
  
  
  
  
  