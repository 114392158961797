/* eslint-disable */ 

export default (state, action) => {
    switch(action.type) {
      case 'GET_GENRES':
        return {
          ...state,
          genres: {
            loading:false,
            error:false,
            data:action.payload
          }
        }
      case 'GENRES_ERROR': 
        return {
          ...state,
          genres: {
            loading:false,
            data:[],
            error:action.payload
          }
        }
      
      default:
        return state;
    }
  };
  
  
  
  
  
  