import React from 'react'
import './../../styles/Navbar.scss'
export default function NavSideBar() {
    return (
        <div className="nav">
             <ul>
                    <li>Genres</li>
                    <li>Resources</li>

                    <li>Authors</li>
                    <li>Articles</li>

                    <li>Blogs</li>
                    <li>About Us</li>
                    <li>Contact Us</li>

                </ul>
        </div>
    )
}
