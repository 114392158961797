import React from 'react';

import SinglePagePDFViewer from "./single-page";
import './../../styles/Pdf.scss'
export default function PdfReader({file}) {
  


  return (


    <div className="">
      <SinglePagePDFViewer pdf={file} /> 
    </div>

    
  );
}