import React from 'react';

const Home = React.lazy(() => import('./pages/Home'));
const NotFound=React.lazy(() => import('./pages/404'));

const Genres=React.lazy(()=>import('./pages/Genres/Genres'))

const BookDetail=React.lazy(()=>import('./pages/Source/BookDetail'))

// const GenresDetail=React.lazy(()=>import('./pages/Genres'))

const AllSources=React.lazy(()=>import('./pages/Source'))
const ReadOnline=React.lazy(()=>import('./pages/Source/ReadOnline'))

const Authors=React.lazy( ()=>import('./pages/Authors') )

const AuthorsDetail=React.lazy( ()=>import('./pages/Authors/AuthorDetail') )

const Blog=React.lazy( ()=>import('./pages/Blog') )

const PostDetail=React.lazy( ()=>import('./pages/Post') )

const Profile=React.lazy( ()=>import('./pages/Profile') )

const ContactUs=React.lazy( ()=> import('./pages/ContactUs') )

const AboutUs=React.lazy( ()=> import('./pages/AboutUs') )

const Password=React.lazy( ()=> import('./pages/Profile/Password') )

const SaveBook=React.lazy( ()=> import('./pages/Profile/SaveBook') )
const DownloadBook=React.lazy( ()=> import('./pages/Profile/DownloadBook') )

const VerifyEmail=React.lazy( ()=> import('./pages/Service/VerifyEmail') )

const ResetPassword=React.lazy( ()=> import('./pages/Service/ResetPassword') )
const Search=React.lazy( ()=> import('./pages/Search') )

let routes = [
	{path: '/', exact: true,name: 'HOME', component: Home},
    {path: '/404', exact: true, name: 'NotFound', component: NotFound},
    {path: '/genres', exact: true, name: 'GENRES', component: Genres},
    {path: '/source/:BOOK_ID', exact: true, name:'books', component: BookDetail},
    {path: '/source',name:'all sources', exact: true,  component: AllSources},
    {path: '/source/:BOOK_ID/read', exact: true, name:'books', component: ReadOnline},

    // {path: '/genres/:GENRES_ID',name:'genres',exact: true,  component: GenresDetail},


    {path: '/authors',name:'Authors Id',exact: true,  component: Authors},

    {path: '/authors/:AUTHOR_ID',name:'Authors Id',exact: true,  component: AuthorsDetail},


    {path: '/blog', exact: true, name: 'blog', component: Blog},

    {path: '/blog/:CATEGORY_NAME/:POST_ID' , exact: true, name: 'post detail' , component: PostDetail} ,


    {path: '/profile/edit', exact: true, name: 'profile', component: Profile},
    {path: '/profile/password', exact: true, name: 'password', component: Password},
    {path: '/profile/savebook', exact: true, name: 'password', component: SaveBook},
    {path: '/profile/downloadbook', exact: true, name: 'password', component: DownloadBook},
    {path: '/profile/regionnote', exact: true, name: 'password', component: Password},

    {path: '/contactus', exact: true, name: 'contact us', component: ContactUs},

    {path: '/aboutus', exact: true, name: 'about us', component: AboutUs},
    
    // {path: '/checkuser', exact: true, name: 'checkuser', component: CheckUser},

    {path: '/service/verifyemail', exact: true, name: 'verify email', component: VerifyEmail},
    {path: '/service/resetpassword', exact: true, name: 'reset password', component: ResetPassword},

    {path: '/search', exact: true, name: 'search page', component: Search},

];

export default routes;
