/* eslint-disable */ 

import React, { createContext, useReducer } from "react";
import genres from "./reducers/genres";
import genresInitialState from "./initialStates/genresInitState";
import blog from "./reducers/blog";
import blogInitialState from "./initialStates/blogInitState";



import editorChoicesInitState from './initialStates/editorChoiceState'
import editorChoice from "./reducers/editorChoice";



export const GlobalContext = createContext({});

export const GlobalProvider = ({ children }) => {
 

  const [genresState,genresDispatch]=useReducer(genres,genresInitialState)
  const [blogState,blogDispatch]=useReducer(blog,blogInitialState)

  const [editorState,editorDispatch]=useReducer(editorChoice,editorChoicesInitState)

  return (
    <GlobalContext.Provider
      value={{
        genresState,
        genresDispatch,
        blogState,
        blogDispatch,
        editorState,
        editorDispatch

       

      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};







