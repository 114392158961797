import React from 'react'
import { useQuery } from '../../helper'
import './../../styles/Auth.scss'
import { Row,Col } from 'react-grid-system';
import SecondFooter from '../../components/Layout/SecondFooter';
import ForgotCard from '../../components/Auth/ForgotCard'

export default function ResetPassword() {
    const query = useQuery()
    let tokenStr=query.get('token')


    return (
        <div style={{overflowX:'hidden'}}>
        
        <Row>
            <Col sm={3.5}></Col>

            <Col sm={5}>
                <div className="forgotpassword"  >

                    <ForgotCard title="Reset password" token={tokenStr} />
                
                </div>
            </Col>

            <Col sm={3.5} ></Col>

        </Row>
        
    

    <SecondFooter/>

</div>
    )
}
