import React, {Suspense} from 'react';

import Header from './Header'
import Footer from './Footer'
import routes from './../../routes'
import {Route, Switch,Redirect} from 'react-router-dom';
import Spinner from '../Spinner';

function Layout(props) {


    const loading = () => <Spinner/>

    return (
        <>
            <Header/>
                <Suspense fallback={loading()}>
					<Switch>
                 
						{routes.map((route, idx) => {

							return route.component ? (
								<Route
												key={idx}
												path={route.path}
												exact={route.exact}
												name={route.name}
												render={props => (
													<route.component {...props} />
												)}/>
										) : (null);
									})
						}

						<Redirect to="/404" />

					</Switch>
				</Suspense>
            <Footer/>
			</>
    )
}
export default Layout;