import React,{useState} from 'react'
import Logo from './../../assets/images/logo.svg'
import './../../styles/Header.scss'
import Button from './../Button'
import { ListIcon,Close } from '../Icon'
import NavSideBar from './navSideBar'
import {Link} from 'react-router-dom'
import { useHistory } from "react-router-dom";

export default function MobileNavBar() {
    const [openNav,handleOpen]=useState(false)
    const history = useHistory();

    function handleOpenNavbar(){
        handleOpen(!openNav)
       
    }  
    return (
        <div className="mobileSize">
                    <Link to="/" >
                        <img src={Logo} width="80px" alt="logo" />

                    </Link>
                    <div className="leftMobileSize">
                        <Button _style={{marginRight:'20px'}} text="LOGIN" click={()=>history.push('/login')} />
                        <div onClick={handleOpenNavbar} style={{paddingTop:'3px'}}>

                            {openNav ?  <Close width="30" height="29" />  : <ListIcon width="30" height="29"  />}
                        </div>
                    </div>

                    {openNav && <div className="navbarWrapper"><NavSideBar/></div>}

        </div>
    )
}
