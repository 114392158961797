import React from 'react';
import './../../styles/Spinner.scss'
const Spinner =()=>{
    return(
            <div className="SpinnerOverlay">
                <div className="SpinnerContainer"></ div>
            </div>
    )
}

export default Spinner;




