/* eslint-disable */ 

import React,{useRef} from 'react'
import { Link } from 'react-router-dom'
import Input from '../Input'
import Button from '../Button'
import {SmallLeftIcon} from '../Icon'
import Label from '../Label'
import './../../styles/Auth.scss'
import { Query } from '../../queries'
import {validateEmail} from '../../helper'
import {toast,ToastContainer} from 'react-toastify'
import { useHistory } from "react-router-dom";
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';

export default function ForgotCard({title,type,token}) {
  const { executeRecaptcha } = useGoogleReCaptcha();


    const emailRef = useRef('')
    const password1Ref = useRef('')
    const password2Ref = useRef('')

    const history = useHistory('')
   const handleForgotPass=async()=>{

        const emailVal=emailRef.current.value
        const password1Val=password1Ref.current.value
        const password2Val=password2Ref.current.value

       if(type==='forgot'){

            if(validateEmail(emailVal)){
              if (!executeRecaptcha) {
                console.log('Execute recaptcha not yet available');

            }else{
                const result = await executeRecaptcha('submit');
                Query(
                    {
                      url: "user/forgotPassword",
                      method: "PUT",
                      data:{
                        email:emailVal,
                        'g-recaptcha-response':result
                      }
                    },
                    (response) => {
                      if(response.status === 404){
                        toast.error(response.message[0].message)

                      }
                      if(response.status===200){
                          toast.success(response.message[0].message)

                      }
                    },
                    (error) => {
                        console.log(error,'erro')
                    }
                  );
              }

            }else{
                toast.error('Please enter valid email')
            }

        }else{
            if(password1Val == password2Val){

                if(password1Val.length>=4){

                    Query(
                        {
                          url: "user/resetPassword",
                          method: "PUT",
                          data:{
                            token:token,
                            newPass:password1Val
                          }
                        },
                        (response) => {
                          if(response.status === 404){
                            toast.error(response.message[0].message)

                          }
                          if(response.status===200){
                              toast.success(response.message[0].message)
                             
                              setTimeout(() => {
                                history.push('/login')
                            },5000)

                          }
                        },
                        (error) => {
                            console.log(error,'erro')
                        }
                      );


                }else{
                    toast.error('Password must be at least 4 characters')
                }
                


            }else{
               toast.error('Password does not match')
            }


        }

        

       }

       
   





    return (
        <>
        <div className="forgotWrapper">
            <h1> {title ? title : 'Forget password'} </h1>
                 

            <p className="forgot">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
            </p>

            <div>

                {
                    type==='forgot' ? <>

                            <Label text="Username" />
                            <Input  myRef={emailRef}  type="text"  placeholder="Email or username" />

                    
                    </>:<>
                            <Label text="new password" />

                            <Input  myRef={password1Ref} type="password"  placeholder="Enter your password" />

                            <Label text="re-new password" />

                            <Input myRef={password2Ref}  type="password"  placeholder="Enter your password" />
                            
                        </>
                }

               

                <Button text="SUBMIT" click={handleForgotPass}  _style={{width:'50%',marginTop:'50px',textTransform:'uppercase'}} />           
           
                <Link to="/signup">
                    <p className="forgotText">You do not have an account?</p>
                </Link>
                <Link to="/login">
                    <p className="forgotText">You have an account?</p>
                </Link>
                

                <Link to='/'>
                    <div style={{display:'flex',alignItems:'center',paddingTop:'41px'}}>
                        <SmallLeftIcon/>
                        <span className="back">BACK TO HOME</span> 
                    </div>
                </Link>
            
            </div>

        </div>

        <ToastContainer
                            position="top-right"
                            hideProgressBar={false}
                            // autoClose={true}
                            newestOnTop={true}
                            closeOnClick={false}
                            draggable={false}
                            rtl={false}
                            theme={'colored'}
                        />
        </>
    )
}
