import Axios from "axios";
// import Swal from "sweetalert2";
import Configs from "../configs/global";
import { getToken } from "../helper";

export const Query= (data, success, err) =>{

    let request={};
    request.method = data.method ? data.method : 'POST';
    request.url = Configs.API + data.url;
    request.headers = data.logout ? undefined : {'x-access-token':getToken()};
    delete data.method;
    delete data.url;
    delete data.logout;
    request = {...request, ...data};
    Axios(request)
        .then(response => {
            typeof success === 'function' && success(response.data);

        }).catch(error => {
         
             err(error);
            
        })
};
