import React from 'react'
import {Row,Col} from 'react-grid-system'
import {Link} from 'react-router-dom'

import {BookIcon,Instagram,FaceBook,Pinterest} from './../Icon'

export default function SecondFooter() {
    return (
        <footer className="secondFooter">
            <div className="container ">
                <Row>
                    <Col  md={6} lg={5} xl={5} xxl={5} xs={12} sm={12}>
                        <div className="allRight">
                            <BookIcon color="#31A27C" />
                            <h1 className="text">© 2021 ImamHussain. All Rights Reserved.</h1>
                        </div>
                    </Col>
                    <Col md={6} lg={5} xl={5} xxl={5} xs={12} sm={12}>
                        <div className="socialGp">
                            <span className="socialLinks">
                                <Link to="https://www.facebook.com/" >
                                    <Instagram color="#123244" />
                                </Link>
                            </span>
                            <span className="socialLinks">
                                <Link to="https://www.facebook.com/" >
                                    <FaceBook color="#123244" />
                                </Link>
                            </span>
                            <span className="socialLinks">
                                <Link to="https://www.facebook.com/" >
                                    <Pinterest color="#123244" />
                                </Link>
                            </span>
                        </div>   
                    </Col>
                </Row>
            </div>
             
        </footer>
    )
}
