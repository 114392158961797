/* eslint-disable */ 

import React,{useEffect} from 'react'
import { isLogin, scrollToTop } from '../../helper'
import { useHistory } from "react-router-dom";
import FormCard from '../../components/Auth/FormCard';
import TextCard from '../../components/Auth/TextCard';
import './../../styles/Auth.scss'
import { Row,Col } from 'react-grid-system';
import SecondFooter from '../../components/Layout/SecondFooter';
import { ToastContainer} from "react-toastify";

export default function Login() {
    const history = useHistory();

    useEffect(() => {
        scrollToTop()
       if(isLogin()){


        history.push('/')



       }
    }, [])



    const textList=['Subscribing to the site is different from subscribing to the site newsletter.',
    'An email will be sent to you only by subscribing to the newsletter.',
    'We guarantee that your email will be protected by us.'
]    
    return (
        <>
        <div style={{display:'flex'}}>
            <div className="authWrapper">
                <Row>
                    <Col sm={2}></Col>

                    <Col sm={8}>
                        <div style={{display:'flex',flexDirection:'row',marginBottom:'50px' }} >

                            <FormCard type="login" />
                            <TextCard textList={textList} text="Enter your email and password to login to your account.
If You do not have an account use your google account or sign up,Please." type="login"/>
                        
                        </div>
                    </Col>

                    <Col sm={2} ></Col>

                </Row>
                
            </div>
        </div>

        <SecondFooter/>

        <ToastContainer
                            position="top-right"
                            hideProgressBar={false}
                            // autoClose={true}
                            newestOnTop={true}
                            closeOnClick={false}
                            draggable={false}
                            rtl={false}
                            theme={'colored'}
                        />
           

        </>
    )
}
