/* eslint-disable */ 

import React, { useEffect,useState } from 'react'
import Spinner from '../../components/Spinner'
import { useQuery } from '../../helper'
import { Query } from '../../queries'
import { Link } from 'react-router-dom'
import {SmallLeftIcon} from '../../components/Icon'
import './../../styles/Service.scss'
import { toast, ToastContainer } from 'react-toastify'
import { useHistory } from "react-router-dom";


export default function VerifyEmail() {
    const query = useQuery()
    let tokenStr=query.get('token')
    let history = useHistory();

    
    const [loading, setLoading] = useState(true)

    const [text,setText] = useState('verifying ...')

    const VerifyUser=()=>{

        Query(
            {
                url: 'verify/user',
                method: 'POST',
                data: {
                    token: tokenStr, 
                   
                }                
            },
            (response) => {

                if(response.status===404){

                    setLoading(false)
                    setText('Verify email is expired.')
                }else{
                     toast.success( `${response.message[0].message} You can login now.`)
                    // setLoading(false)

                    setTimeout(() => {
                        history.push('/login')
                    },5000)
                   

                }
                




        
                

                },
            (error)=>{

                console.log(error,'error')

                
            
            })



    }
    useEffect(() => {
        VerifyUser()
    },[tokenStr])

    return (
        <>
        <div className="mt2 verifyWrapper">
            <h1>{text}</h1>
            {/* VerifyEmail {tokenStr} */}

            {
                loading ? <Spinner /> :<>

           
                <div className="backHome">
                    <SmallLeftIcon/>
                    <Link to='/'> <span className="back">BACK TO HOME</span> </Link>
                </div>
            </>
            }

        
           
        </div>

        <ToastContainer
        position="top-right"
        hideProgressBar={false}
        // autoClose={true}
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        rtl={false}
        theme={"colored"}
        />
                
        </>
    )
}
