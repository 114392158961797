
import React,{useRef,useState} from 'react'
import { ListIcon,ArrowDown, ArrowTop } from '../Icon'
import Logo from './../../assets/images/logo.svg'
import './../../styles/Header.scss'
import Input from './../Input'
import Button from './../Button'
import HeaderDiscover from './../Discover'
import { Row, Col } from 'react-grid-system';
import MobileNavBar from './../MobileSize'
import {Link} from 'react-router-dom'
import { useHistory } from "react-router-dom";
import {isLogin} from './../../helper'
function Header() {

    const myInput=useRef('');
    const history = useHistory();

    const [isShown, setIsShown] = useState(false);


    const  handleClick= () => {



        const s=myInput.current.value

        history.push(`/search?name=${s}`)

        
      }
    

    // function handleOpenNavbar(){
    //     handleOpen(!openNav)
    // }  


    function handleLogin(){
        if(isLogin()){
            history.push('/profile/edit')
        }else{
            history.push('/login')
        }
    }

    // useEffect(() => {


    //     setTimeout(() => {
    //         setCount(count+1)
    //     } , 1000);
      


    // },[count])

    


    return (
        <div className="shadowHeader">
            <header className="container header">
                <div className="laptopSize">
                    <Row>
                    <Col sm={6}>
                        <div className="leftSide">
                            <Link to='/' >
                                <img src={Logo} alt="Imam hussain library logo"  />
                            </Link>
                            

                            <div className="discover" onMouseEnter={() => setIsShown(true)} >

                                <span>
                                    <ListIcon width="16" height="15.99"/>

                                </span>
                                <span>
                                    <p>DISCOVER</p>

                                </span>
                                <span>
                                    <ArrowDown/>

                                </span>

                                {
                            isShown&&
                            <div className="showDiscover"  
                                onMouseLeave={()=> setIsShown(false)}
                                >
                                <div className="wrapperDiscover">
                                    <div className="hoverDiscover">
                                        <span>
                                            <ListIcon width="16" height="15.99" />

                                        </span>
                                        <span>
                                            <p>DISCOVER</p>

                                        </span>
                                        <span>
                                            <ArrowTop/>

                                        </span>
                                       
                                        
                                       
                                    </div>
                                    <HeaderDiscover/>

                                </div>
                            </div>
                        }
                    
                            
                            </div>
                        </div>
                        
                        
                    </Col>
                        <Col sm={6}>
                            <Row>

                                <Col sm={9}>

                                    <Input icon myRef={myInput} clickHandle={handleClick} type='text'  />

                                </Col>
                                <Col sm={3} style={{display:'flex',justifyContent:'flex-end'}}>

                                    <Button text={isLogin() ? 'PROFILE' :'LOGIN'} click={handleLogin} />

                                </Col>

                            </Row>

                        
                    </Col>

                </Row>
                </div>

                <MobileNavBar/>
                


            </header>
        </div>
    )
}
export default Header;