
export  function ListIcon({color,width,height}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 15.956">
          <g id="Group_18" data-name="Group 18" transform="translate(-32.3 -32.3)">
            <path id="Path_29" data-name="Path 29" d="M33.352,34.4h13.9a1.052,1.052,0,1,0,0-2.1h-13.9A1.067,1.067,0,0,0,32.3,33.352,1.038,1.038,0,0,0,33.352,34.4Z" fill="#123244"/>
            <path id="Path_30" data-name="Path 30" d="M47.2,63.9H33.352a1.052,1.052,0,0,0,0,2.1h13.9a1.053,1.053,0,1,0-.044-2.1Z" transform="translate(0 -17.748)" fill="#123244"/>
            <path id="Path_31" data-name="Path 31" d="M47.2,48.1H33.352a1.052,1.052,0,0,0,0,2.1h13.9a1.053,1.053,0,1,0-.044-2.1Z" transform="translate(0 -8.874)" fill="#123244"/>
          </g>
        </svg>
        
    )
}


export  function ArrowDown({color}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14.828" height="8.414" viewBox="0 0 14.828 8.414">
        <path id="Path_9" data-name="Path 9" d="M20.5,11.5l-6,6-6-6" transform="translate(-7.086 -10.086)" fill="none" stroke="#123244" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/>
      </svg>
      

    )
}

export  function SearchIcon({color}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15.752" height="15.752" viewBox="0 0 15.752 15.752">
            <path id="Path_8" data-name="Path 8" d="M18.493,17.251l-2.974-2.966A6.929,6.929,0,0,0,17,10a7,7,0,1,0-7,7,6.929,6.929,0,0,0,4.287-1.478l2.966,2.974a.878.878,0,1,0,1.242-1.242ZM4.75,10A5.249,5.249,0,1,1,10,15.247,5.249,5.249,0,0,1,4.75,10Z" transform="translate(-3 -3)" fill={color} />
        </svg>


    )
}



export function ArrowTop({color}){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="14.828" height="8.414" viewBox="0 0 14.828 8.414">
                <path id="Path_9" data-name="Path 9" d="M20.5,17.5l-6-6-6,6" transform="translate(-7.086 -10.5)" fill="none" stroke="#123244" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/>
        </svg>

    )
    
}


export function Close({width,height,color}){
    
    return(
        <svg id="Outline" fill={color ? color : '#123244'} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M18,6h0a1,1,0,0,0-1.414,0L12,10.586,7.414,6A1,1,0,0,0,6,6H6A1,1,0,0,0,6,7.414L10.586,12,6,16.586A1,1,0,0,0,6,18H6a1,1,0,0,0,1.414,0L12,13.414,16.586,18A1,1,0,0,0,18,18h0a1,1,0,0,0,0-1.414L13.414,12,18,7.414A1,1,0,0,0,18,6Z"/>
        </svg>
    )
}

export function ArrowLeft({color}){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="13.999" height="25.998" viewBox="0 0 13.999 25.998">
            <path id="Path_32" data-name="Path 32" d="M31.67,11.5,20.085,23.085l-8.206-8.206L8.5,11.5" transform="translate(24.085 -7.086) rotate(90)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" />
        </svg>

    )
}


export function ArrowRight({color,width,height}){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width={ width ? width : "13.999"} height={ height ? height : "25.998"} viewBox="0 0 13.999 25.998">
            <path id="Path_33" data-name="Path 33" d="M23.17,11.585,11.585,0l-2.9,2.9L0,11.585" transform="translate(12.999 1.414) rotate(90)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/>
        </svg>

    )
}


export function FlashLeft({color}){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="26.058" height="15.249" viewBox="0 0 26.058 15.249">
            <path id="arrow-left" d="M15.249,7.57,8.61,1.038,7.572,0,6.639.937,0,7.367,2.073,9.539,6.121,5.6V26.058h2.9V5.6l4.152,4.045Z" transform="translate(0 15.249) rotate(-90)" fill={color} />
        </svg>

    )
}

export function FlashRight({color}){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="26.058" height="15.249" viewBox="0 0 26.058 15.249">
        <path id="arrow-right" d="M15.249,18.488,8.61,25.02,7.572,26.058l-.934-.937L0,18.691l2.073-2.172,4.048,3.938V0h2.9V20.457l4.152-4.045Z" transform="translate(0 15.249) rotate(-90)" fill={color}  />
      </svg>
      

    )
}

export function SaveIcon({color}){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="14.142" height="18.06" viewBox="0 0 14.142 18.06" fill={color} >
            <path id="Path_13" data-name="Path 13" d="M6.09,21.06a1,1,0,0,1-1-1L4.94,5.4A2.26,2.26,0,0,1,7.12,3.05L16.71,3a2.27,2.27,0,0,1,2.23,2.31l.14,14.66a.99.99,0,0,1-1.49.87l-5.7-3.16L6.6,20.91a1.2,1.2,0,0,1-.51.15Zm5.76-5.55a1.11,1.11,0,0,1,.5.12l4.71,2.61L16.94,5.29c0-.2-.13-.34-.21-.33l-9.6.09c-.08,0-.19.13-.19.33l.12,12.9,4.28-2.63a1.06,1.06,0,0,1,.51-.14Z" transform="translate(-4.938 -3)"/>
        </svg>

    )
}

export function SavedIcon(){
    return(
       

      
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="14.142" height="18.06" viewBox="0 0 59.000000 76.000000"
         preserveAspectRatio="xMidYMid meet">
        
        <g transform="translate(0.000000,76.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none">
        <path d="M32 734 l-22 -15 0 -348 c0 -354 2 -371 38 -371 8 0 65 32 127 71
        l113 70 129 -71 c82 -45 136 -68 146 -64 15 6 17 39 17 353 0 336 -1 348 -20
        369 -20 21 -27 22 -263 22 -203 0 -246 -3 -265 -16z"/>
        </g>
        </svg>
        


    )
}

export function BookIcon({color}){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="22.936" height="21.326" viewBox="0 0 22.936 21.326">
  <g id="Group_15" data-name="Group 15" transform="translate(-1.096 -1.096)">
    <path id="Path_11" data-name="Path 11" d="M13.776,19.745a8.97,8.97,0,0,0-6.648-2.663V1.846a10.064,10.064,0,0,1,8.621,4.59,10.064,10.064,0,0,1,8.621-4.59V17.081a8.97,8.97,0,0,0-6.648,2.663" transform="translate(-3.185 0)" fill="none" stroke="#31a27c" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5"/>
    <line id="Line_1" data-name="Line 1" y2="11.181" transform="translate(12.564 8.637)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5"/>
    <path id="Path_12" data-name="Path 12" d="M3.572,11.254H1.846V27.345H23.282V11.254H21.556" transform="translate(0 -5.673)" fill="none" stroke="#31a27c" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5"/>
  </g>
</svg>

    )
}


export function Instagram ({color}){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="21.01" height="21.01" viewBox="0 0 21.01 21.01">
  <path id="Path_3" data-name="Path 3" d="M21.947,7.175a7.711,7.711,0,0,0-.488-2.55,5.15,5.15,0,0,0-1.213-1.861,5.15,5.15,0,0,0-1.861-1.213,7.711,7.711,0,0,0-2.549-.488C14.715,1.012,14.358,1,11.5,1s-3.21.012-4.33.063a7.711,7.711,0,0,0-2.55.488A5.15,5.15,0,0,0,2.762,2.764,5.15,5.15,0,0,0,1.55,4.624a7.711,7.711,0,0,0-.488,2.549C1.012,8.3,1,8.652,1,11.5s.012,3.21.063,4.33a7.711,7.711,0,0,0,.488,2.55,5.15,5.15,0,0,0,1.213,1.861,5.149,5.149,0,0,0,1.861,1.213,7.711,7.711,0,0,0,2.549.488C8.3,22,8.652,22.01,11.5,22.01s3.21-.012,4.33-.063a7.711,7.711,0,0,0,2.55-.488,5.372,5.372,0,0,0,3.073-3.073,7.711,7.711,0,0,0,.488-2.549c.051-1.122.063-1.479.063-4.332S22,8.3,21.947,7.175ZM20.056,15.75a5.81,5.81,0,0,1-.362,1.949A3.478,3.478,0,0,1,17.7,19.694a5.812,5.812,0,0,1-1.95.362c-1.109.05-1.44.061-4.245.061s-3.137-.011-4.245-.061a5.812,5.812,0,0,1-1.949-.362A3.254,3.254,0,0,1,4.1,18.908,3.254,3.254,0,0,1,3.316,17.7a5.81,5.81,0,0,1-.362-1.95c-.05-1.109-.061-1.44-.061-4.245S2.9,8.367,2.954,7.26A5.812,5.812,0,0,1,3.316,5.31,3.254,3.254,0,0,1,4.1,4.1a3.254,3.254,0,0,1,1.208-.786,5.812,5.812,0,0,1,1.95-.362C8.369,2.9,8.7,2.893,11.5,2.893s3.137.011,4.245.061a5.812,5.812,0,0,1,1.949.362,3.254,3.254,0,0,1,1.209.786,3.254,3.254,0,0,1,.786,1.208,5.811,5.811,0,0,1,.362,1.95c.05,1.109.061,1.44.061,4.245S20.106,14.642,20.056,15.75ZM11.5,6.11A5.395,5.395,0,1,0,16.9,11.5,5.395,5.395,0,0,0,11.5,6.11Zm0,8.9a3.5,3.5,0,1,1,3.5-3.5A3.5,3.5,0,0,1,11.5,15.006ZM18.373,5.9a1.261,1.261,0,1,1-1.261-1.261A1.261,1.261,0,0,1,18.373,5.9Z" transform="translate(-1 -1)" fill="#123244"/>
</svg>

    )
}


export function FaceBook({color}){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="11.01" height="21.014" viewBox="0 0 11.01 21.014">
  <path id="Path_4" data-name="Path 4" d="M10.909.151V3.484H8.927A1.719,1.719,0,0,0,8.7,3.469,1.847,1.847,0,0,0,7.46,3.94l0,0a2.073,2.073,0,0,0-.384,1.209c0,.054,0,.109.006.162V7.689h3.7l-.49,3.738H7.089v9.582H3.222V11.426H0V7.689H3.222V4.936c-.006-.089-.01-.194-.01-.3A4.867,4.867,0,0,1,4.537,1.292l0,0A4.743,4.743,0,0,1,7.8,0c.079,0,.158,0,.236.006H8.029l.346,0a21.118,21.118,0,0,1,2.636.165l-.1-.011Z" transform="translate(-0.001 0.005)" fill="#123244"/>
</svg>


    )
}

export function Pinterest({color}){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="16.905" height="21.113" viewBox="0 0 16.905 21.113">
  <path id="Path_6" data-name="Path 6" d="M167,133.643c-5.936,0-8.951,4.114-8.951,7.564a6.672,6.672,0,0,0,.229,1.822,4.008,4.008,0,0,0,2.338,2.82.419.419,0,0,0,.619-.3c.069-.218.195-.745.264-.974a.563.563,0,0,0-.172-.676,3.28,3.28,0,0,1-.687-1.261,4.16,4.16,0,0,1-.149-1.123,5.852,5.852,0,0,1,6.178-5.811c3.358,0,5.215,1.983,5.215,4.642a10.89,10.89,0,0,1-.229,2.246c-.527,2.441-1.9,4.206-3.748,4.206a1.9,1.9,0,0,1-1.983-2.338c.183-.721.447-1.478.676-2.212a7.779,7.779,0,0,0,.447-2.086,1.642,1.642,0,0,0-1.707-1.822c-1.341,0-2.418,1.352-2.418,3.14a4.947,4.947,0,0,0,.08.894,4.424,4.424,0,0,0,.321,1.032s-1.375,5.627-1.6,6.613a13.055,13.055,0,0,0-.034,4.607c.011.137.206.172.3.069a12.833,12.833,0,0,0,2.269-3.965c.138-.55.883-3.346.883-3.346a3.577,3.577,0,0,0,3.06,1.524c3.266,0,5.7-2.372,6.487-5.822a10.989,10.989,0,0,0,.275-2.5C174.957,136.978,171.8,133.643,167,133.643Z" transform="translate(-158.052 -133.643)" fill="#123244" fillRule="evenodd"/>
</svg>


    )
}

export function HotIcon(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="34.001" height="44" viewBox="0 0 34.001 44">
            <g id="Group_22" data-name="Group 22" transform="translate(-63.969 -7.5)">
                <path id="Path_34" data-name="Path 34" d="M69.081,21.748c-.079.657-.145,1.315-.241,1.97a8.638,8.638,0,0,0,.432,4.9,4.369,4.369,0,0,0,1.058,1.531,1.249,1.249,0,0,0,1.918-.055,3.534,3.534,0,0,0,.893-3.2,26.678,26.678,0,0,1-.454-3.712,9.4,9.4,0,0,1,3.623-7.429c.43-.36.912-.658,1.37-.984.272-.194.549-.382.834-.557A3.768,3.768,0,0,0,80.266,9.94c-.133-.723-.316-1.438-.48-2.156-.056-.246,0-.362.261-.228a12.318,12.318,0,0,1,3.943,2.618c2.705,3.136.263,7.386.588,10.986a5.927,5.927,0,0,0,.313,1.473c.553,1.539,2.407,3.377,4.137,2.35a5.186,5.186,0,0,0,1.966-3.252,9.156,9.156,0,0,0,.076-3.89q-.037-.193-.079-.369a.266.266,0,0,1,.451-.244,23.724,23.724,0,0,1,5.149,8.05,25.977,25.977,0,0,1,1.087,4.31,23.858,23.858,0,0,1,.282,4.423,21.727,21.727,0,0,1-.563,4.337A18.948,18.948,0,0,1,95.947,42.4a16.7,16.7,0,0,1-2.376,3.573,15.983,15.983,0,0,1-3.344,2.894q-.446.289-.91.549A17.059,17.059,0,0,1,66.4,42.986c-3.239-5.855-3.511-13.69.813-19.1.581-.727,1.143-1.47,1.714-2.205Z" transform="translate(0 0)" fill="#ffe966"/>
                <path id="Path_35" data-name="Path 35" d="M90.807,9.94a6.148,6.148,0,0,1,.087.681c2.137,3.085-.068,7.108.241,10.54a5.926,5.926,0,0,0,.313,1.473c.553,1.539,2.407,3.377,4.137,2.35a3.127,3.127,0,0,0,.845-.759,4.714,4.714,0,0,1-1-1.59,5.926,5.926,0,0,1-.313-1.473c-.325-3.6,2.118-7.85-.588-10.986a12.319,12.319,0,0,0-3.943-2.618c-.257-.134-.317-.018-.261.228C90.491,8.5,90.675,9.216,90.807,9.94Zm17.412,19.65a25.978,25.978,0,0,0-1.087-4.31,23.724,23.724,0,0,0-5.149-8.05.266.266,0,0,0-.451.244q.042.176.079.369a9.156,9.156,0,0,1-.076,3.89c-.008.032-.017.065-.025.1a20.019,20.019,0,0,1,1.638,3.45,25.98,25.98,0,0,1,1.087,4.31,23.856,23.856,0,0,1,.282,4.423,21.727,21.727,0,0,1-.563,4.337A18.949,18.949,0,0,1,102.5,42.4a16.7,16.7,0,0,1-2.376,3.573,15.982,15.982,0,0,1-3.344,2.894q-.446.289-.91.549a16.336,16.336,0,0,1-6.1,1.964,16.669,16.669,0,0,0,10.081-1.964q.464-.259.91-.549a15.982,15.982,0,0,0,3.344-2.894,16.7,16.7,0,0,0,2.376-3.573,18.949,18.949,0,0,0,1.449-4.054,21.728,21.728,0,0,0,.563-4.337A23.857,23.857,0,0,0,108.219,29.589ZM75.83,28.623a4.37,4.37,0,0,0,1.058,1.531,1.249,1.249,0,0,0,1.918-.055,3.078,3.078,0,0,0,.9-1.745,8.318,8.318,0,0,1-.4-4.057,9.733,9.733,0,0,1-.063-1.113,10.248,10.248,0,0,1,.093-1.327c-.525.679-1.045,1.362-1.581,2.033a14.341,14.341,0,0,0-2.222,3.924,6.477,6.477,0,0,0,.3.808Z" transform="translate(-10.541 0)" fill="#ffe236"/>
                <path id="Path_36" data-name="Path 36" d="M138.5,186.045c.225.68.445,1.363.677,2.041a5.871,5.871,0,0,0,2.137,3.008,4.022,4.022,0,0,0,2.522.8,2.268,2.268,0,0,0,2.278-2.738,13.974,13.974,0,0,0-1.2-3.488,32.194,32.194,0,0,1-1.794-4.606,7.484,7.484,0,0,1,2.247-7.83c.44-.422.906-.816,1.366-1.216.1-.089.2-.267.351-.2s.089.256.088.39a19.52,19.52,0,0,0,.147,3.019,11.524,11.524,0,0,0,2.784,5.716c1.241,1.494,2.66,2.827,3.931,4.295a16.671,16.671,0,0,1,2.941,4.651,6.993,6.993,0,0,1-.7,6.734,9.619,9.619,0,0,1-6.224,4.275,11.708,11.708,0,0,1-9.137-1.389,8.8,8.8,0,0,1-3.595-11.319c.326-.726.682-1.439,1.025-2.157Z" transform="translate(-66.122 -149.757)" fill="#ffc166"/>
                <path id="Path_37" data-name="Path 37" d="M138.362,191.093a4.022,4.022,0,0,0,2.522.8,2.441,2.441,0,0,0,1.666-.668c-.068-.044-.136-.089-.2-.137a5.872,5.872,0,0,1-2.137-3.008c-.232-.678-.452-1.36-.677-2.041l-.149-.014c-.343.719-.7,1.431-1.025,2.157a9.193,9.193,0,0,0-.708,2.3A5.309,5.309,0,0,0,138.362,191.093Zm19.649-1.207a16.672,16.672,0,0,0-2.941-4.651c-1.271-1.467-2.689-2.8-3.931-4.295a11.523,11.523,0,0,1-2.784-5.716,19.514,19.514,0,0,1-.147-3.019c0-.134.072-.324-.088-.39s-.248.114-.351.2c-.46.4-.927.794-1.366,1.216a8.7,8.7,0,0,0-1.917,2.611,11.934,11.934,0,0,0,2.669,5.095c1.241,1.494,2.66,2.827,3.931,4.295a16.672,16.672,0,0,1,2.941,4.651,6.993,6.993,0,0,1-.7,6.734,9.619,9.619,0,0,1-6.224,4.275q-.517.118-1.029.2a12.74,12.74,0,0,0,5.013-.2,9.619,9.619,0,0,0,6.224-4.275A6.993,6.993,0,0,0,158.012,189.886Z" transform="translate(-67.159 -149.757)" fill="#ffb74e"/>
            </g>
        </svg>

    )
}

export function VerticalList({color}){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19">
        <g id="Group_42" data-name="Group 42" transform="translate(-1479.5 -278.5)">
          <rect id="Rectangle_45" data-name="Rectangle 45" width="5" height="20" rx="2.5" transform="translate(1499.5 292.5) rotate(90)" fill={color}/>
          <rect id="Rectangle_47" data-name="Rectangle 47" width="5" height="20" rx="2" transform="translate(1499.5 292.5) rotate(90)" fill={color}/>
          <rect id="Rectangle_44" data-name="Rectangle 44" width="5" height="20" rx="2" transform="translate(1499.5 285.5) rotate(90)" fill={color}/>
          <rect id="Rectangle_43" data-name="Rectangle 43" width="5" height="20" rx="2" transform="translate(1499.5 278.5) rotate(90)" fill={color}/>
        </g>
      </svg>
      

    )

}

export function HorizontalList({color}){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="9" viewBox="0 0 19 9">
        <g id="Group_44" data-name="Group 44" transform="translate(-1513 -278.493)">
          <rect id="Rectangle_40" data-name="Rectangle 40" width="5" height="9" rx="2" transform="translate(1527 278.493)" fill={color} />
          <rect id="Rectangle_41" data-name="Rectangle 41" width="5" height="9" rx="2" transform="translate(1520 278.493)" fill={color} />
          <rect id="Rectangle_42" data-name="Rectangle 42" width="5" height="9" rx="2" transform="translate(1513 278.493)" fill={color} />
        </g>
        
      </svg>
      

    )

}

export function UnknownSocialMedia({color}){
    return(
        <svg id="icons" xmlns="http://www.w3.org/2000/svg" width="16.417" height="16.417" viewBox="0 0 16.417 16.417">
            <path id="link_round-_1110_" data-name="link_round-[#1110]" d="M338.97,3120.445a4.937,4.937,0,0,0-6.981,0l-2.108,2.109,1.163,1.162,2.109-2.108a3.291,3.291,0,0,1,4.654,4.654l-2.109,2.108,1.164,1.164,2.109-2.11a4.936,4.936,0,0,0,0-6.979Zm-7.707,12.361a3.291,3.291,0,0,1-4.654-4.654l2.109-2.108-1.165-1.164-2.109,2.109a4.937,4.937,0,1,0,6.982,6.981l2.107-2.109-1.164-1.164-2.109,2.109Zm2.8-8.619,1.163,1.163-4.89,4.891-1.163-1.163Z" transform="translate(-323.999 -3119)" fill={color} fillRule="evenodd"/>
        </svg>
    )

}

export function TwitterIcon({color}){
    return(
        <svg id="Bold"  fill={color} viewBox="0 0 24 24" width="22"  xmlns="http://www.w3.org/2000/svg"><path d="m21.534 7.113c.976-.693 1.797-1.558 2.466-2.554v-.001c-.893.391-1.843.651-2.835.777 1.02-.609 1.799-1.566 2.165-2.719-.951.567-2.001.967-3.12 1.191-.903-.962-2.19-1.557-3.594-1.557-2.724 0-4.917 2.211-4.917 4.921 0 .39.033.765.114 1.122-4.09-.2-7.71-2.16-10.142-5.147-.424.737-.674 1.58-.674 2.487 0 1.704.877 3.214 2.186 4.089-.791-.015-1.566-.245-2.223-.606v.054c0 2.391 1.705 4.377 3.942 4.835-.401.11-.837.162-1.29.162-.315 0-.633-.018-.931-.084.637 1.948 2.447 3.381 4.597 3.428-1.674 1.309-3.8 2.098-6.101 2.098-.403 0-.79-.018-1.177-.067 2.18 1.405 4.762 2.208 7.548 2.208 8.683 0 14.342-7.244 13.986-14.637z"/></svg>

    )
}

export function ZoomIcon({color}) {

    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill={color} >
            <g id="layer1" transform="translate(0 -1036.362)">
                <g id="g4608-9" transform="translate(0 1036.362)">
                <g id="g10290" transform="translate(0 0)">
                    <g id="g10513" transform="translate(0)">
                    <path id="circle5005-3" d="M11.815,0A11.815,11.815,0,1,0,19.54,20.733l6.02,6.02a.844.844,0,1,0,1.194-1.194l-6.02-6.02A11.8,11.8,0,0,0,11.815,0Zm0,1.688A10.127,10.127,0,1,1,1.688,11.815,10.115,10.115,0,0,1,11.815,1.688ZM11.8,6.752a.844.844,0,0,0-.831.857v3.363H7.609a.844.844,0,1,0,0,1.688h3.363v3.366a.844.844,0,1,0,1.688,0V12.659h3.366a.844.844,0,1,0,0-1.688H12.659V7.609a.844.844,0,0,0-.857-.857Z" transform="translate(0)"/>
                    </g>
                </g>
                </g>
            </g>
        </svg>

    )


}



export function DownloadIcon ({color}) { 
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
            <path id="Path_42" data-name="Path 42" d="M12,4a1,1,0,0,0-1,1v9.529L6.782,10.306a1.044,1.044,0,0,0-1.476,1.478l5.9,5.91a1.178,1.178,0,0,0,1.581,0l5.9-5.91a1.044,1.044,0,0,0-1.476-1.478L13,14.529V5A1,1,0,0,0,12,4ZM5,21a1,1,0,0,1,1-1H18a1,1,0,0,1,0,2H6A1,1,0,0,1,5,21Z" transform="translate(-5 -4)" fill={color} />
        </svg>

    )
}

export function ReadOnlineIcon(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
            <path id="Path_52" data-name="Path 52" d="M19,3H5A3,3,0,0,0,2,6v8a3,3,0,0,0,3,3h6v2H7a1,1,0,0,0,0,2H17a1,1,0,0,0,0-2H13V17h6a3,3,0,0,0,3-3V6A3,3,0,0,0,19,3Zm1,11a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V6A1,1,0,0,1,5,5H19a1,1,0,0,1,1,1Z" transform="translate(-2 -3)" fill="#123244"/>
        </svg>

    )
}

export function SimpleStar({color,gradient}){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22.858" viewBox="0 0 24 22.858">
            {
                gradient &&  <defs>
                <linearGradient id="linear-gradient01" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#fcd635"/>
                <stop offset="1" stopColor="#f7a928"/>
                </linearGradient>
            </defs>
            }
           
  <g id="layer1" transform="translate(-0.999 -1037.086)">
    <path id="path4512-3" d="M-48.556,63.857c-.854.608-6.333-3.247-7.382-3.255s-6.59,3.758-7.433,3.136,1.14-7.009.824-8.007-5.621-5.088-5.288-6.08,7.037-1.085,7.891-1.693,3.116-6.9,4.165-6.894,3.209,6.338,4.053,6.96,7.546.822,7.863,1.82-5.054,5-5.386,5.995,1.548,7.411.694,8.019Z" transform="translate(68.849 996.022)" fill={gradient?'url(#linear-gradient01)':'#e4ebe9'} />
  </g>
</svg>


    )
}

export function StarIcon() { 
    return (
        <svg id="Group_49" data-name="Group 49" xmlns="http://www.w3.org/2000/svg"  width="24" height="22.858" viewBox="0 0 18.899 18">
            <defs>
                <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#fcd635"/>
                <stop offset="1" stopColor="#f7a928"/>
                </linearGradient>
            </defs>
            <g id="layer1">
                <path id="path4512-3" d="M-52.657,59.012c-.672.479-4.987-2.557-5.813-2.564s-5.189,2.959-5.854,2.47.9-5.519.649-6.3-4.426-4.007-4.164-4.788,5.541-.854,6.214-1.333,2.454-5.436,3.28-5.429,2.527,4.991,3.192,5.481,5.942.647,6.191,1.433-3.98,3.939-4.241,4.72,1.219,5.836.547,6.314Z" transform="translate(67.851 -41.064)" fill="url(#linear-gradient)"/>
            </g>
        </svg>

    )
}


export function UserIcon(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
  <g id="user-profile" transform="translate(-32.557 -382.557)">
    <circle id="Ellipse_1" data-name="Ellipse 1" cx="21" cy="21" r="21" transform="translate(32.557 382.557)" fill="#eff5f3"/>
    <path id="Path_20" data-name="Path 20" d="M16,14a6,6,0,1,0-6-6,6,6,0,0,0,6,6ZM16,4a4,4,0,1,1-4,4A4,4,0,0,1,16,4Zm0,12A10,10,0,0,0,6,26v3a1,1,0,0,0,2,0V26a8,8,0,1,1,16,0v3a1,1,0,0,0,2,0V26A10,10,0,0,0,16,16Z" transform="translate(37.557 387.557)" fill="#bababa"/>
  </g>
</svg>

    )
}


export function LikeIcon(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg"  width="27.613" height="27.614" viewBox="0 0 27.613 27.614">
            <defs>
                <linearGradient id="linear-gradientLike" x1="-0.273" y1="0.357" x2="1" y2="0.357" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#136c4e"/>
                    <stop offset="1" stopColor="#31a27c"/>
                </linearGradient>
            </defs>
            <g id="Group_71" data-name="Group 71" transform="translate(-0.529 -289.063)">
            <path id="Path_58" data-name="Path 58" d="M2.5,291.709H4.474a1.968,1.968,0,0,1,1.972,1.972v13.807a1.968,1.968,0,0,1-1.972,1.972H2.5a1.968,1.968,0,0,1-1.972-1.972V293.681A1.968,1.968,0,0,1,2.5,291.709Z" transform="translate(0 7.216)" fill="#e4ebe9" fillRule="evenodd"/>
            <path id="Path_59" data-name="Path 59" d="M6.065,289.063a1.974,1.974,0,0,0-1.974,1.972v7.889l-1.974,2.959v.971c0,.008,0,.015,0,.019l0,10.844,2.55,2.383a2.054,2.054,0,0,0,1.4.576H21.84a1.972,1.972,0,0,0,1.972-1.972V300.9a1.974,1.974,0,0,0-1.972-1.974H11.977V294.45a1.965,1.965,0,0,0-.262-.982L9.74,290.045a1.971,1.971,0,0,0-1.71-.982Z" transform="translate(4.33 0)" fill="url(#linear-gradientLike)"/>
            </g>
      </svg>
      

    )
}

export function DisLikeIcon(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg"  width="27.613" height="27.614" viewBox="0 0 27.613 27.614">
            <defs>
                <linearGradient id="linear-gradientDLike" x1="-0.273" y1="0.357" x2="1" y2="0.357" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#909090"/>
                    <stop offset="1" stopColor="#C7C7C7"/>
                </linearGradient>
            </defs>
            <g id="Group_71" data-name="Group 71" transform="translate(-0.529 -289.063)">
            <path id="Path_58" data-name="Path 58" d="M2.5,291.709H4.474a1.968,1.968,0,0,1,1.972,1.972v13.807a1.968,1.968,0,0,1-1.972,1.972H2.5a1.968,1.968,0,0,1-1.972-1.972V293.681A1.968,1.968,0,0,1,2.5,291.709Z" transform="translate(0 7.216)" fill="#e4ebe9" fillRule="evenodd"/>
            <path id="Path_59" data-name="Path 59" d="M6.065,289.063a1.974,1.974,0,0,0-1.974,1.972v7.889l-1.974,2.959v.971c0,.008,0,.015,0,.019l0,10.844,2.55,2.383a2.054,2.054,0,0,0,1.4.576H21.84a1.972,1.972,0,0,0,1.972-1.972V300.9a1.974,1.974,0,0,0-1.972-1.974H11.977V294.45a1.965,1.965,0,0,0-.262-.982L9.74,290.045a1.971,1.971,0,0,0-1.71-.982Z" transform="translate(4.33 0)" fill="url(#linear-gradientDLike)"/>
            </g>
      </svg>
      

    )
}








export function GmailIcon(){
    return(
        <svg id="Group_83" data-name="Group 83" xmlns="http://www.w3.org/2000/svg" width="53.128" height="53.128" viewBox="0 0 53.128 53.128">
  <path id="Path_67" data-name="Path 67" d="M11.774,156.026l-1.849,6.9-6.759.143a26.61,26.61,0,0,1-.2-24.805h0l6.018,1.1,2.636,5.981a15.853,15.853,0,0,0,.149,10.674Z" transform="translate(0 -123.921)" fill="#fbbb00"/>
  <path id="Path_68" data-name="Path 68" d="M287.144,208.176a26.554,26.554,0,0,1-9.469,25.678h0l-7.579-.387-1.073-6.7a15.832,15.832,0,0,0,6.812-8.084h-14.2V208.176h25.516Z" transform="translate(-234.48 -186.575)" fill="#518ef8"/>
  <path id="Path_69" data-name="Path 69" d="M70.536,324.581h0a26.572,26.572,0,0,1-40.028-8.127l8.608-7.047A15.8,15.8,0,0,0,61.883,317.5Z" transform="translate(-27.343 -277.303)" fill="#28b446"/>
  <path id="Path_70" data-name="Path 70" d="M69.173,6.115l-8.606,7.045a15.8,15.8,0,0,0-23.289,8.271l-8.654-7.085h0A26.569,26.569,0,0,1,69.173,6.115Z" transform="translate(-25.654)" fill="#f14336"/>
</svg>

    )
}

export function LoginFaceBook(){
    return(
        <svg id="Group_84" data-name="Group 84" xmlns="http://www.w3.org/2000/svg" width="53.128" height="53.128" viewBox="0 0 53.128 53.128">
  <circle id="Ellipse_17" data-name="Ellipse 17" cx="26.564" cy="26.564" r="26.564" transform="translate(0 0)" fill="#3b5998"/>
  <path id="Path_71" data-name="Path 71" d="M53.149,39.061h-4.74V56.426H41.228V39.061H37.812v-6.1h3.416V29.009c0-2.824,1.341-7.246,7.245-7.246l5.32.022v5.924h-3.86a1.462,1.462,0,0,0-1.523,1.663v3.592h5.367Z" transform="translate(-19.907 -11.458)" fill="#fff"/>
</svg>


    )
}

export function LoginAmazon(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
  <g id="Group_85" data-name="Group 85" transform="translate(0.5 0.5)">
    <path id="Path_72" data-name="Path 72" d="M0,26.5A26.5,26.5,0,1,1,26.5,53,26.5,26.5,0,0,1,0,26.5Z" fill="#fff" stroke="#192c18" strokeWidth="1"/>
    <path id="Path_73" data-name="Path 73" d="M18.516,21.986a5.83,5.83,0,0,0-.984,3.413h0a5.136,5.136,0,0,0,1.431,3.89,5.072,5.072,0,0,0,3.657,1.372,8.667,8.667,0,0,0,2.431-.29,7.12,7.12,0,0,0,3.18-2.2c.078.1.213.284.408.566a6.39,6.39,0,0,0,.417.565c.085.1.231.25.433.461a9.438,9.438,0,0,0,.708.666.8.8,0,0,0,.839-.06q.2-.173,2.6-2.256a.5.5,0,0,0,.231-.4.866.866,0,0,0-.173-.463l-.664-.883a3.362,3.362,0,0,1-.417-.9,4.053,4.053,0,0,1-.219-1.372v-7c0-.076-.008-.322-.028-.735a4.091,4.091,0,0,0-.087-.811c-.036-.125-.1-.352-.175-.676a2.883,2.883,0,0,0-.258-.755,5,5,0,0,0-.392-.592,5.948,5.948,0,0,0-.533-.622A7.319,7.319,0,0,0,25.773,11.2h-.721a9.06,9.06,0,0,0-4.5,1.387A5.547,5.547,0,0,0,18.142,16.4a.82.82,0,0,0-.03.2c0,.27.165.443.491.523l3.326.406a.74.74,0,0,0,.58-.666,2.365,2.365,0,0,1,.894-1.447,3.163,3.163,0,0,1,1.65-.608h.225a2.161,2.161,0,0,1,1.908.871,5.147,5.147,0,0,1,.407,2.485v.491c-1.177.1-2.027.171-2.544.231a14.532,14.532,0,0,0-3.844.954A6.076,6.076,0,0,0,18.516,21.986Zm4.552,4.641a2.754,2.754,0,0,1-.562-1.809l0,0a3.1,3.1,0,0,1,2.544-3.208,10.565,10.565,0,0,1,2.544-.233V22.1c0,.616,0,1.061-.014,1.332a5.512,5.512,0,0,1-.173,1.054,4.649,4.649,0,0,1-.477,1.2,2.944,2.944,0,0,1-1.878,1.56,1.421,1.421,0,0,0-.219.03,1.564,1.564,0,0,1-.246.026A1.873,1.873,0,0,1,23.068,26.627Z" transform="translate(1.826 1.167)" fill="#221f1f" fillRule="evenodd"/>
    <path id="Path_74" data-name="Path 74" d="M33.035,32.081a.992.992,0,0,1,.173-.233,4.571,4.571,0,0,1,1.391-.664,9.525,9.525,0,0,1,2.137-.318,1.723,1.723,0,0,1,.549.03c.869.08,1.391.223,1.562.437a.909.909,0,0,1,.115.517v.2a6.586,6.586,0,0,1-.549,2.385,5.605,5.605,0,0,1-1.53,2.212.433.433,0,0,1-.262.115.259.259,0,0,1-.115-.028c-.119-.06-.145-.165-.087-.318a10.963,10.963,0,0,0,1.069-3.5.751.751,0,0,0-.115-.463c-.193-.229-.732-.346-1.62-.346-.326,0-.712.02-1.153.06-.485.06-.928.115-1.332.173a.327.327,0,0,1-.233-.06.091.091,0,0,1-.028-.115.2.2,0,0,1,.028-.085Z" transform="translate(3.437 3.214)" fill="#f90"/>
    <path id="Path_75" data-name="Path 75" d="M9.66,31.533c.1-.155.248-.165.461-.03A30.806,30.806,0,0,0,25.85,35.7a31.134,31.134,0,0,0,11.191-2.113l.417-.173c.183-.079.312-.135.39-.173a.517.517,0,0,1,.682.173q.244.346-.159.636c-.348.25-.791.541-1.332.867a21.632,21.632,0,0,1-5.55,2.286,23.483,23.483,0,0,1-5.983.809,23.157,23.157,0,0,1-8.527-1.576,23.439,23.439,0,0,1-7.2-4.439.39.39,0,0,1-.177-.288A.3.3,0,0,1,9.66,31.533Z" transform="translate(1 3.272)" fill="#f90"/>
  </g>
</svg>


    )
}


export function SmallLeftIcon(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="7.496" height="13" viewBox="0 0 7.496 13">
  <path id="Fill-35" d="M-206.353-303.4l-6.547-6.5,6.547-6.5.949.949L-211-309.9l5.6,5.551-.949.949" transform="translate(212.9 316.4)" fill="#123244"/>
</svg>

    )
}


export function ProfileIcon(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="80%"  viewBox="0 0 20.502 20.505" fill="#BABAB9" >
            <g id="Page-1" transform="translate(0.251 0.255)">
                <g id="Dribbble-Light-Preview" transform="translate(-180 -2159)">
                <g id="icons" transform="translate(56 160)">
                    <path id="profile-_1341_" data-name="profile-[#1341]" d="M134,2009a4,4,0,1,1,4.019-4,4.013,4.013,0,0,1-4.019,4m3.776.673a5.979,5.979,0,0,0,2.182-5.6,6.032,6.032,0,0,0-11.986.93,5.974,5.974,0,0,0,2.252,4.673,9.533,9.533,0,0,0-6.22,8.218,1.012,1.012,0,0,0,1,1.109.99.99,0,0,0,.993-.891,8.055,8.055,0,0,1,16,0,.99.99,0,0,0,.993.891,1.011,1.011,0,0,0,1-1.109,9.53,9.53,0,0,0-6.22-8.218" stroke="#fff" strokeWidth="0.5" fillRule="evenodd"/>
                </g>
                </g>
            </g>
        </svg>

    )
}


export function PasswordIcon(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="26" viewBox="0 0 21 26" fill="#123244" >
  <path id="Path_92" data-name="Path 92" d="M14,15.75a1.862,1.862,0,0,0-1.25,3.262V20.75a1.25,1.25,0,0,0,2.5,0V19.012A1.863,1.863,0,0,0,14,15.75Zm6.25-5V8.25a6.25,6.25,0,1,0-12.5,0v2.5A3.75,3.75,0,0,0,4,14.5v8.75A3.75,3.75,0,0,0,7.75,27h12.5A3.75,3.75,0,0,0,24,23.25V14.5A3.75,3.75,0,0,0,20.25,10.75Zm-10-2.5a3.75,3.75,0,1,1,7.5,0v2.5h-7.5Zm11.25,15a1.25,1.25,0,0,1-1.25,1.25H7.75A1.25,1.25,0,0,1,6.5,23.25V14.5a1.25,1.25,0,0,1,1.25-1.25h12.5A1.25,1.25,0,0,1,21.5,14.5Z" transform="translate(-3.5 -1.5)" stroke="#fff" strokeWidth="1"/>
</svg>


    )
}

export function RegionIcon(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="20.657" height="20.657" viewBox="0 0 20.657 20.657">
  <path id="Path_83" data-name="Path 83" d="M18.791,25.126h-2.3a.576.576,0,0,0,0,1.152h2.3a.576.576,0,1,0,0-1.152ZM18.791,9h-2.3a.576.576,0,0,0,0,1.152h2.3a.576.576,0,0,0,0-1.152ZM25.7,15.911a.576.576,0,0,0-.576.576v2.3a.576.576,0,0,0,1.152,0v-2.3A.576.576,0,0,0,25.7,15.911ZM9.576,19.367a.576.576,0,0,0,.576-.576v-2.3a.576.576,0,0,0-1.152,0v2.3A.576.576,0,0,0,9.576,19.367Zm12.1-9.215h3.454v2.924a.576.576,0,0,0,1.152,0v-3.5A.576.576,0,0,0,25.7,9h-4.03a.576.576,0,1,0,0,1.152ZM13.47,9H9.576A.576.576,0,0,0,9,9.576v3.5a.576.576,0,0,0,1.152,0V10.152H13.47A.576.576,0,1,0,13.47,9Zm.155,16.126H10.152V22.179a.576.576,0,0,0-1.152,0V25.7a.576.576,0,0,0,.576.576h4.049a.576.576,0,1,0,0-1.152Zm14.957,0h-2.3V21.094a.576.576,0,1,0-1.152,0v4.031H22.009a.576.576,0,0,0,0,1.152h3.117v2.3a.576.576,0,0,0,1.152,0v-2.3h2.3a.576.576,0,0,0,0-1.152Z" transform="translate(-8.75 -8.75)" fill="#123244" stroke="#123244" stroke-linecap="round" stroke-linejoin="bevel" strokeWidth="0.5"/>
</svg>


    )
}


export function LogOutIcon(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="17.003" height="16" viewBox="0 0 17.003 16">
        <path id="Path_91" data-name="Path 91" d="M7,6A1,1,0,0,0,7,4H5A1,1,0,0,0,4,5V19a1,1,0,0,0,1,1H7a1,1,0,0,0,0-2H6V6Zm13.82,5.42L18,7.42a1,1,0,1,0-1.63,1.16L18.09,11H10a1,1,0,0,0,0,2h8l-1.8,2.4a1,1,0,0,0,1.6,1.2l3-4a1,1,0,0,0,.02-1.18Z" transform="translate(-4 -4)"/>
      </svg>
      
    )
}

export function CircleIcon({click}){
    return(
        <div onClick={click} style={{ width:'38px',height:'38px',border:'5px solid #E4EBE9' , borderRadius:'50%',cursor:'pointer' }} ></div>
      
    )
}


export function GreenCircleIcon({click}){
    return(
        <div onClick={click} style={{ width:'38px',height:'38px',border:'5px solid #31A27C' , borderRadius:'50%', display:'flex' ,cursor:'pointer' ,justifyContent:'center',alignItems:'center' }} >
            <div style={{ width:'20px',height:'20px',backgroundColor:'#31A27C' , borderRadius:'50%'  }} ></div>

        </div>

      
    )
}

export function PdfIcon(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="17.594" height="17.596" viewBox="0 0 17.594 17.596">
            <path id="file_pdf-_1729_" data-name="file_pdf-[#1729]" d="M96.3,1239H84.514a.58.58,0,0,0-.386.142.779.779,0,0,0-.128.436v8.22h1.759v-6.2a1.116,1.116,0,0,1,.165-.635.8.8,0,0,1,.553-.2h8.079v5.278h5.278v1.759h1.759v-3.133Zm-8.779,13.289a.88.88,0,0,0-.88-.88h-.88v1.76h.88A.88.88,0,0,0,87.519,1252.289Zm1.759-.091a2.639,2.639,0,0,1-2.639,2.639h-.88v1.76H84v-7.038h2.639A2.639,2.639,0,0,1,89.278,1252.2Zm4.4.091a.962.962,0,0,0-.88-.971h-.88v3.519h.88a.806.806,0,0,0,.88-.788Zm1.759-.091v1.759A2.639,2.639,0,0,1,92.8,1256.6H90.158v-7.038H92.8A2.647,2.647,0,0,1,95.436,1252.2Zm6.158-2.639v1.759H98.075v1.76h3.519v1.759H98.075v1.76H96.316v-7.038Z" transform="translate(-84 -1239)" fill="#123244" fillRule="evenodd"/>
        </svg>

    )
}


export function TrashIcon(){
    return(
        <svg id="Page-1" xmlns="http://www.w3.org/2000/svg" width="18.479" height="17.599" viewBox="0 0 18.479 17.599">
            <g id="Dribbble-Light-Preview">
                <g id="icons">
                <path id="delete-_1487_" data-name="delete-[#1487]" d="M129.468,214.079h1.848v-7.04h-1.848Zm3.7,0h1.848v-7.04h-1.848Zm-5.544,1.76h9.24V205.28h-9.24Zm1.848-12.319h5.544v-1.76h-5.544Zm7.392,0V200h-9.24v3.52H123v1.76h2.772V217.6h12.935V205.28h2.772v-1.76Z" transform="translate(-123 -200)" fill="#123244" fillRule="evenodd"/>
                </g>
            </g>
        </svg>

    )
}

export function TikIcon(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="16.373" height="11.461" viewBox="0 0 16.373 11.461">
            <path id="Path_38" data-name="Path 38" d="M13.731,31.462a.819.819,0,0,1-.579-.24L8.24,26.31A.819.819,0,1,1,9.4,25.152l4.333,4.333,9.245-9.245A.819.819,0,1,1,24.133,21.4l-9.824,9.824A.818.818,0,0,1,13.731,31.462Z" transform="translate(-8 -20.001)" fill="#31a27c"/>
        </svg>

    )
}

export function PrintIcon(){
    return(<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <path id="Path_82" data-name="Path 82" d="M7,10a1,1,0,1,0,1,1A1,1,0,0,0,7,10ZM19,6H18V3a1,1,0,0,0-1-1H7A1,1,0,0,0,6,3V6H5A3,3,0,0,0,2,9v6a3,3,0,0,0,3,3H6v3a1,1,0,0,0,1,1H17a1,1,0,0,0,1-1V18h1a3,3,0,0,0,3-3V9A3,3,0,0,0,19,6ZM8,4h8V6H8Zm8,16H8V16h8Zm4-5a1,1,0,0,1-1,1H18V15a1,1,0,0,0-1-1H7a1,1,0,0,0-1,1v1H5a1,1,0,0,1-1-1V9A1,1,0,0,1,5,8H19a1,1,0,0,1,1,1Z" transform="translate(-2 -2)" fill="#123244"/>
            </svg>
    )
}

export function FullScreenIcon(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="Layer_81" data-name="Layer 81" transform="translate(-4.71 -4.71)">
    <path id="Path_62" data-name="Path 62" d="M49.963,4.71H43.779a.989.989,0,1,0,0,1.979h5.194v5.194a.989.989,0,1,0,1.979,0V5.7A.989.989,0,0,0,49.963,4.71Z" transform="translate(-22.242)" fill="#123244"/>
    <path id="Path_63" data-name="Path 63" d="M11.883,4.71H5.7A.989.989,0,0,0,4.71,5.7v6.184a.989.989,0,1,0,1.979,0V6.689h5.194a.989.989,0,1,0,0-1.979Z" fill="#123244"/>
    <path id="Path_64" data-name="Path 64" d="M11.883,48.974H6.689V43.779a.989.989,0,1,0-1.979,0v6.184a.989.989,0,0,0,.989.989h6.184a.989.989,0,1,0,0-1.979Z" transform="translate(0 -22.242)" fill="#123244"/>
    <path id="Path_65" data-name="Path 65" d="M49.963,42.79a.989.989,0,0,0-.989.989v5.194H43.779a.989.989,0,1,0,0,1.979h6.184a.989.989,0,0,0,.989-.989V43.779A.989.989,0,0,0,49.963,42.79Z" transform="translate(-22.242 -22.242)" fill="#123244"/>
    <path id="Path_66" data-name="Path 66" d="M27.831,4.71h-5.5a.879.879,0,0,0,0,1.759h4.617v4.617a.879.879,0,1,0,1.759,0v-5.5A.879.879,0,0,0,27.831,4.71ZM11.086,26.951H6.469V22.334a.879.879,0,0,0-1.759,0v5.5a.879.879,0,0,0,.879.879h5.5a.879.879,0,1,0,0-1.759Z" fill="#123244"/>
  </g>
</svg>

    )
}


export function InfoIcon(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <g id="Group_51" data-name="Group 51" transform="translate(0)">
    <path id="Path_53" data-name="Path 53" d="M11,10.979a1,1,0,1,1,2,0v6a1,1,0,0,1-2,0Zm1-4.928a1,1,0,1,0,1,1A1,1,0,0,0,12,6.051Z" transform="translate(-2 -2)" fill="#e4ebe9"/>
    <path id="Path_54" data-name="Path 54" d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2ZM4,12a8,8,0,1,0,8-8A8,8,0,0,0,4,12Z" transform="translate(-2 -2)" fill="#e4ebe9" fillRule="evenodd"/>
  </g>
</svg>
    )
}

export function EditProfileIcon(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="20.502" height="20.505" viewBox="0 0 20.502 20.505">
  <g id="Dribbble-Light-Preview" transform="translate(-179.748 -2158.745)">
    <g id="icons" transform="translate(56 160)">
      <path id="profile-_1341_" data-name="profile-[#1341]" d="M134,2009a4,4,0,1,1,4.019-4,4.013,4.013,0,0,1-4.019,4m3.776.673a5.979,5.979,0,0,0,2.182-5.6,6.032,6.032,0,0,0-11.986.93,5.974,5.974,0,0,0,2.252,4.673,9.533,9.533,0,0,0-6.22,8.218,1.012,1.012,0,0,0,1,1.109.99.99,0,0,0,.993-.891,8.055,8.055,0,0,1,16,0,.99.99,0,0,0,.993.891,1.011,1.011,0,0,0,1-1.109,9.53,9.53,0,0,0-6.22-8.218" stroke="#fff" strokeWidth="0.5" fillRule="evenodd"/>
    </g>
  </g>
</svg>

    )
}