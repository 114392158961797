import React from 'react'
import { Link } from 'react-router-dom'
import BlueLogo from './../../assets/images/blue.png'
import GreenLogo from './../../assets/images/green.png'
import {SmallLeftIcon} from './../Icon'
export default function TextCard({type,text,textList}) {
    return (
        <div className="formTextCard" style={{ background: type === 'login' ? 'radial-gradient(110.6% 393.85% at -23.46% 140.28%, #123244  0%, #31A27C 100%)' : '#123244' , borderRadius: type === 'login' ? '0 10px 10px 0' : '10px 0 0 10px' }} >
            
            <div className="backHome" style={{ left: type === 'signup' && '0' }} >
                <Link to='/'>

                    <SmallLeftIcon/>
                    <span>BACK TO HOME</span> 

                </Link>

            </div>
            
            <img className="textCardLogo" src={ type === 'login' ? BlueLogo : GreenLogo} alt="ImamhusssainLibrary" />
            <p className="cardText">
                {text}
            </p>
            <ul className="cardList">
                {
                   textList && textList.map((item,i) => <li key={i} > {item} </li> )
                }
            </ul>
            
        </div>
    )
}
