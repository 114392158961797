import React from 'react'
import './../../styles/Header.scss'
import {Link} from 'react-router-dom'
export default function HeaderDiscover() {

    const links1=[
        {title:'Brows Genres',link:'/genres'},
        {title:"Editor's Choice",link:'/source?editor_suggestion=true&sortBy=title&page=1'},
        {title:"Trending",link:'/source?sortBy=trending&page=1'},
        {title:"All Books",link:'/source?sortBy=title&page=1'},

    ]

    const links2=[
        {title:'Sources',link:'/source?sortBy=title&page=1'},
        {title:"Authors",link:'/authors?sortBy=a&page=1'},
        {title:"Blogs",link:'/blog?sortBy=new&page=1'},
        // {title:"About Us",link:'/source?sortBy=title&page=1'},
        {title:"Contact Us",link:'/contactus'},


    ]


    // const links2=['Link Item','Book page','Genres 223','Hussain genres']
    return (
        <div className="listGroup" >
            <div>

                <h1>

                </h1>
                <div className="listGroup">
                    <ul>
                        {
                            links1.map((item,i)=><Link  key={i} to={item.link}>
                                                    <li>{item.title}</li>

                            
                            
                                                </Link>)
                        }

                      
                    </ul>
                    <ul>
                    {
                            links2.map((item,i)=><Link  key={i} to={item.link}>
                                                        <li>{item.title}</li>

    
    
                                                </Link>)
                        }
                    </ul>

                </div>
            </div>

            <div className="resourceWrapper" >
                <h1 >OTHER WEBSITE</h1>
                <ul>

                    <li>
                        <a href="https://fifthgalaxy.org/" target="_blank" rel="noreferrer" >FIFTHGALAXY</a>
                    </li>

                    <li> <a href="http://ashura.photo/" target="_blank" rel="noreferrer">ASHURA PHOTO</a></li>

                    {/* <li> <Link to="/source?sortBy=title&page=1&sourceType=61768b3b3abdb0508019b634">Articles</Link></li> */}

                    <li><a href="https://hussainpodcasts.com/" target="_blank" rel="noreferrer" >HUSSAIN PODCASTS</a></li>
                    <li><a  href="https://hussainsounds.com/" target="_blank" rel="noreferrer">HUSSAIN SOUNDS</a></li>

                </ul>

                    
            </div>

                


            
        </div>
    )
}
