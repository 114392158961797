import React, { useState } from "react";
import { Document, Page,pdfjs } from "react-pdf";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { ArrowLeft, ArrowRight,BookIcon, FullScreenIcon } from "../Icon";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function SinglePage(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page
  const handle = useFullScreenHandle();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function changePage(offset) {
    setPageNumber(pageNumber => pageNumber + offset);
  }

  function previousPage() {
    if(pageNumber>1){

      changePage(-1);

    }
    
  }

  function nextPage() {
    if(pageNumber<numPages){
      changePage(1);
    }
    // setPageNumber(pageNumber+1)
  }

  const { pdf } = props;

  return (
    <FullScreen handle={handle} style={{overflowY:'auto'}} >
      <div className="container" >
        <div className="pdfWrapper">

          <Document
            file={{
              url:pdf
            }}
            options={{ workerSrc: "/pdf.worker.js" }}
            onLoadSuccess={onDocumentLoadSuccess}
          >
              <Page  pageNumber={pageNumber} />
              
          </Document>
          <div  className="prev">
              
                <div  disabled={pageNumber <= 1} onClick={previousPage}>
                  <ArrowLeft  color={pageNumber <= 1 ? "#707070" : '#123244' } />
                </div>
          </div>

          <div className="next">
                <div
                  onClick={nextPage}
                >
                  <ArrowRight color={pageNumber >= numPages ? "#707070" : '#123244' } />
                </div>
          </div>

        </div>

        

      </div>
     
      
      <footer className="readFooter">
        <div className="progressSection">

          <p className="readSection">You read {pageNumber} page of {numPages} page</p>

          <progress className="progressBar" value={(pageNumber/numPages)*100} max="100"> { (pageNumber/numPages) * 100} </progress>



        </div>
       
         <div className="allRight">
                            <BookIcon color="#31A27C" />
                            <h1 className="text">© 2021 ImamHussain. All Rights Reserved.</h1>
                        </div>
         <div className="fullScreen" onClick={handle.enter}>

           <p>Full Screen</p>
           <FullScreenIcon/>
           
        </div>               
      </footer>
    </FullScreen>
  );
}
